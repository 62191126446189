<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

export default defineComponent({
  components: { ExceptionDisplay, LoadingDisplay },
  name: 'SchoolInvoicingPage',
  data () {
    return {
      isLoading: false,
      error: '',
      schoolId: '',
      dateSelected: '2022-01-01',
      invoiceFiles: [],
      generatedInvoices: []
    }
  },

  async mounted () {
    this.schoolId = this.$route.params.schoolId
    document.runMaterializeInit()
    this.loadInvoiceList()
  },

  methods: {
    loadInvoiceList: async function () {
      this.isLoading = true
      this.error = ''
      const apiClient = await this.$api.createApiClient()
      apiClient
        .get('badmin/schools/' + this.schoolId + '/invoices')
        .then((r) => {
          this.invoiceFiles = r.data
          this.isLoaded = true
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.handleUnAuthorized()
          } else {
            this.error = 'There was an error trying to get this data'
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    generateInvoice: async function () {
      this.isLoading = true
      this.error = ''
      const apiClient = await this.$api.createApiClient()
      apiClient
        .post('badmin/schools/' + this.schoolId + '/invoices/' + this.dateSelected + '/generate')
        .then(() => {
          this.isLoaded = true
          this.loadInvoiceList()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.handleUnAuthorized()
          } else {
            this.error = 'There was an error trying to get this data'
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    handleUnAuthorized: function () {
      this.$router.push('/login')
    }
  }

})
</script>

<template>

  <div class="row">

    <h3>School: {{ schoolId }}</h3>
    <h5>Invoices</h5>

    <exception-display :error="error"></exception-display>
    <loading-display :isLoading="isLoading"></loading-display>

    <input type="text" v-model="dateSelected" />
    <a class="btn blue" @click="generateInvoice()">Regenerate</a>

    <div v-show="!isLoading" class="col s12">
      <h5>Generated</h5>
      <table>
        <thead>
          <tr>
            <td>File</td>
            <td>Created</td>
            <td>Last Updated</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="invoice in invoiceFiles" :key="invoice.downloadUri">
            <td>
              <a v-bind:href="invoice.downloadUri" class="valign-wrapper">
                <i class="material-icons margin-medium-right">download</i>
                {{ invoice.filename }}
              </a>
            </td>
            <td>{{ invoice.createdDate }}</td>
            <td>{{ invoice.lastUpdatedDate }}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

</template>
