<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

export default defineComponent({
  components: { ExceptionDisplay, LoadingDisplay },
  name: 'SchoolRegisterPage',
  data () {
    return {
      newSchool: {
        name: '',
        email: '',
        pin: '',
        timezoneId: 'Pacific/Auckland',
        countryCode: 'NZ'
      },
      isLoading: false,
      error: ''
    }
  },

  async mounted () {
    document.runMaterializeInit()
  },

  methods: {
    createSchool: async function () {
      this.isLoading = true
      this.error = ''
      const apiClient = await this.$api.createApiClient()
      apiClient
        .post('badmin/schools', JSON.stringify(this.newSchool))
        .then(() => {
          this.$router.push('/schools')
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.handleUnAuthorized()
          } else {
            this.error = 'There was an error trying to get this data'
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    handleUnAuthorized: function () {
      this.$router.push('/login')
    }
  }

})
</script>

<template>
  <div class="row">
    <div>
      <h5>Register School</h5>

      <exception-display :error="error"></exception-display>
      <loading-display :isLoading="isLoading"></loading-display>

      <div>
        <div class="col s12 m12 l6">
          <div>
            <strong>Name</strong>
          </div>
          <div>
            <input name="Name" type="text" v-model="newSchool.name" />
          </div>
        </div>

        <div class="col s12 m12 l6">
          <div>
            <strong>Email</strong>
          </div>
          <div>
            <input name="Email" type="text" v-model="newSchool.email" />
          </div>
        </div>

        <div class="col s12 m12 l6">
          <div>
            <strong>Pin</strong>
          </div>
          <div>
            <input name="Pin" type="text" v-model="newSchool.pin" />
          </div>
        </div>

        <div class="col s12 m12 l6">
          <div>
            <strong>Country Code</strong>
          </div>
          <div>
            {{ newSchool.countryCode }}
          </div>
        </div>

        <div class="col s12 m12 l6">
          <div>
            <strong>Timezone Id</strong>
          </div>
          <div>
            {{ newSchool.timezoneId }}
          </div>
        </div>

      </div>

      <a @click="createSchool()" class="btn blue">Create</a>

    </div>
  </div>
</template>
