<script>
import Chart from 'chart.js'

export default {
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  mounted () {
    try {
      console.debug(this.chartdata)
      console.debug(this.options)
      const ctx = document.getElementById('theChart')
      var myBarChart = new Chart(ctx, {
        type: 'bar',
        data: this.chartdata,
        options: this.options
      })
    } catch (e) {
      console.debug(e)
    }
  }
}
</script>

<template>
  <div>
    <canvas id="theChart"></canvas>
  </div>
</template>

<style>
</style>
