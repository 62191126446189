<script>
import emitter from 'tiny-emitter/instance'

const eventKeyLoginStatusChanged = 'login-status-changed'

export default {
  data () {
    return {
      isLoggedIn: false
    }
  },
  async mounted () {
    await this.refreshLoggedInUIElements()
    emitter.on(eventKeyLoginStatusChanged, this.refreshLoggedInUIElements)
  },
  methods: {
    refreshLoggedInUIElements: async function () {
      this.isLoggedIn = await this.$api.getIsLoggedIn()
    },
    logout: async function () {
      await this.$api.logout()
    }
  }
}
</script>

<template>
  <header>
    <nav id="header-nav" class="blue darken-4 white-text">
      <div class="container">
        <div class="nav-wrapper">
          <router-link to="/" class="brand-logo center">
            <img
              src="@/assets/oss-logo-header.png"
              class="responsive-img oss-logo" />
          </router-link>

          <div>
            <a href="#" data-target="slide-out" class="sidenav-trigger">
              <i class="material-icons">menu</i>
            </a>
          </div>

        </div>
      </div>
    </nav>

    <div>
      <ul id="slide-out" class="sidenav sidenav-fixed">

        <li class="blue darken-3 school-display white-text center-align">
          <span>ADMIN</span>
        </li>

        <li v-if="!isLoggedIn">
          <router-link to="/login" class="waves-effect sidenav-close">
            <i class="material-icons">input</i> Login
          </router-link>
        </li>

        <li v-if="isLoggedIn">
          <router-link to="/schools" class="waves-effect sidenav-close">
            <i class="material-icons">people</i> Schools
          </router-link>
        </li>

        <li v-if="isLoggedIn">
          <router-link to="/signins/feed" class="waves-effect sidenav-close">
            <i class="material-icons">rss_feed</i> Sign In Feed
          </router-link>
        </li>

        <li v-if="isLoggedIn">
          <router-link to="/perf/db" class="waves-effect sidenav-close">
            <i class="material-icons">speed</i> Db Perf
          </router-link>
        </li>

        <li v-show="isLoggedIn">
          <a class="waves-effect sidenav-close" @click="logout()">
            <i class="material-icons">directions_run</i> Log out
          </a>
        </li>
      </ul>
    </div>
  </header>
</template>
