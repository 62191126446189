import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'
import LandingPage from '../views/LandingPage.vue'
import LoginPage from '../views/LoginPage.vue'
import LogoutPage from '../views/LogoutPage.vue'
import SchoolsPage from '../views/SchoolsPage.vue'
import SchoolPage from '../views/SchoolPage.vue'
import SignInFeedPage from '../views/SignInFeedPage.vue'
import StudentPage from '../views/StudentPage.vue'
import EziCustomerPage from '../views/EziCustomerPage.vue'
import DbPerfAnalysisPage from '../views/DbPerfAnalysisPage.vue'
import SchoolRegisterPage from '../views/SchoolRegisterPage.vue'
import SchoolInvoicingPage from '../views/SchoolInvoicingPage.vue'
import api from '@/data/ApiLayer'

const routes:RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Landing',
    component: LandingPage,
    meta: {
      title: 'Landing'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogoutPage,
    meta: {
      title: 'Logout'
    }
  },
  {
    path: '/schools',
    name: 'Schools',
    component: SchoolsPage,
    meta: {
      title: 'Schools'
    }
  },
  {
    path: '/schools/register',
    name: 'Register School',
    component: SchoolRegisterPage,
    meta: {
      title: 'Register School'
    }
  },
  {
    path: '/schools/:schoolId/students',
    name: 'School',
    component: SchoolPage,
    meta: {
      title: 'School'
    }
  },
  {
    path: '/schools/:schoolId/invoicing',
    name: 'SchoolInvoicing',
    component: SchoolInvoicingPage,
    meta: {
      title: 'School Invoicing'
    }
  },
  {
    path: '/signins/feed',
    name: 'Sign In Feed',
    component: SignInFeedPage,
    meta: {
      title: 'Sign In Feed'
    }
  },
  {
    path: '/schools/:schoolId/students/:studentId',
    name: 'Student',
    component: StudentPage,
    meta: {
      title: 'Student'
    }
  },
  {
    path: '/ezidebit/schools/:schoolId/students/:studentId',
    name: 'EziCustomer',
    component: EziCustomerPage,
    meta: {
      title: 'Ezi Customer'
    }
  },
  {
    path: '/perf/db',
    name: 'Db Perf Analysis',
    component: DbPerfAnalysisPage,
    meta: {
      title: 'Db Perf Analysis'
    }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

trackRouter(router)

router.beforeEach(async (toRoute, fromRoute, next) => {
  let routeTitle = 'Oss Gym'

  if (toRoute.meta) {
    if (typeof toRoute.meta.title === 'string') {
      routeTitle = toRoute.meta.title + ' | ' + routeTitle
    }
  }

  window.document.title = routeTitle

  console.debug('nav', fromRoute.path, toRoute.path)

  // redirect if no auth token found
  if (!toRoute.meta.noAuth && toRoute.path !== '/login') {
    console.debug('checking token..')
    const token = await api.authClient.getToken()
    if (!token) {
      console.debug('no valid token found, redirecting..')
      next({ path: '/login' })
      return
    }
  }

  next()
})

export default router
