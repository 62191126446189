<script>
import { defineComponent } from 'vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import emitter from 'tiny-emitter/instance'

const eventKeyLoginStatusChanged = 'login-status-changed'

export default defineComponent({
  components: { LoadingDisplay, ExceptionDisplay, ValidationErrorDisplay },
  name: 'LoginPage',
  data () {
    return {
      isLoading: false,
      isLoggedIn: false,
      error: '',
      validationErrors: []
    }
  },

  async mounted () {
    this.isLoggedIn = await this.$api.getIsLoggedIn()
    if (this.isLoggedIn) {
      await this.authCheck()
    }
  },

  methods: {
    authCheck: async function () {
      this.error = ''
      this.validationErrors = []
      this.isLoading = true
      const apiClient = await this.$api.createApiClient()
      apiClient
        .get('auth2/check')
        .then(() => { this.handleAuthSuccess() })
        .catch((e) => { this.handleFailedRequest(e) })
        .then(() => { this.isLoading = false })
    },
    handleAuthSuccess: async function () {
      console.debug('checking permissions..')
      const { isGranted } = await this.$api.authClient.getPermission('admin:master')
      if (!isGranted) {
        console.debug('not granted!')
        this.error = 'You do not have adequate permissions to access this area!'
        return
      }
      emitter.emit(eventKeyLoginStatusChanged)
      this.$router.push('/')
    },
    login: async function () {
      this.error = ''
      this.validationErrors = []
      this.isLoading = true
      await this.$api.login()
    },
    logout: async function () {
      this.error = ''
      this.validationErrors = []
      this.isLoading = true
      await this.$api.logout()
    },
    handleFailedRequest: function (e) {
      const data = this.$api.handleFailedRequest(e)
      this.validationErrors = data.validationErrors
      this.error = data.error
    }
  }
})
</script>

<template>
  <div class="row margin-large-top-bottom">
    <div class="col s12 l6 offset-l3">
      <div class="card-panel center-align blue darken-4" id="login-form">

        <div>
          <img src="@/assets/oss-logo-words.png" class="responsive-img oss-logo-full margin-large-top-bottom" />
        </div>

        <div class="left-align">
          <exception-display :error="error"></exception-display>
          <validation-error-display :validationErrors="validationErrors"></validation-error-display>
        </div>

        <loading-display :isLoading="isLoading" :is-white="true" />

        <div v-if="isLoading" class="white-text">
          Logging in...
        </div>
        <div v-else>
          <div class="margin-large-top-bottom" v-if="!isLoggedIn">
            <a class="btn blue" @click="login()" data-test-id="login-form-submit">login</a>
          </div>
          <div class="margin-large-top-bottom" v-if="isLoggedIn">
            <a class="btn red" @click="logout()">logout</a>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>
