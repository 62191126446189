<script lang="ts">
export default {
  props: {
    validationErrors: Array
  }
}
</script>

<template>
  <div v-if="validationErrors.length > 0" class="row">
    <div class="error-message">
      <p v-for="e in validationErrors" v-bind:key="e" class="valign-wrapper">
        <i class="material-icons">error</i>
        {{ e }}
      </p>
    </div>
  </div>
</template>
