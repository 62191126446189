<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

export default defineComponent({
  components: { ExceptionDisplay, LoadingDisplay },
  name: 'SchoolPage',
  data () {
    return {
      schoolId: '',
      school: {
        error: '',
        isLoading: false,
        data: {}
      },
      students: {
        shouldLoad: false,
        error: '',
        isLoading: false,
        list: []
      }
    }
  },

  async mounted () {
    this.schoolId = this.$route.params.schoolId
    document.runMaterializeInit()
    this.loadSchoolMeta()
  },

  methods: {
    loadSchoolMeta: async function () {
      this.school.isLoading = true
      this.school.error = ''
      const apiClient = await this.$api.createApiClient()
      apiClient
        .get('badmin/schools/' + this.schoolId + '/meta')
        .then((r) => {
          this.school.data = r.data
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.handleUnAuthorized()
          } else {
            this.school.error = 'There was an error trying to get this data'
          }
        })
        .then(() => {
          this.school.isLoading = false
        })
    },
    loadStudentsList: async function () {
      this.students.shouldLoad = true
      this.students.isLoading = true
      this.students.error = ''
      const apiClient = await this.$api.createApiClient()
      apiClient
        .get('badmin/schools/' + this.schoolId + '/students')
        .then((r) => {
          this.students.list = r.data
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.handleUnAuthorized()
          } else {
            this.students.error = 'There was an error trying to get this data'
          }
        })
        .then(() => {
          this.students.isLoading = false
        })
    },
    updateSchoolMeta: async function () {
      this.school.isLoading = true
      this.school.error = ''
      const apiClient = await this.$api.createApiClient()
      apiClient
        .put('badmin/schools/' + this.schoolId + '/meta', JSON.stringify(this.school.data))
        .then(() => {
          this.loadSchoolMeta()
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.handleUnAuthorized()
          } else {
            this.school.error = 'There was an error trying to get this data'
          }
        })
        .then(() => {
          this.school.isLoading = false
        })
    },
    renegerateSchoolWebsite: async function () {
      this.school.isLoading = true
      this.school.error = ''
      const apiClient = await this.$api.createApiClient()
      apiClient
        .post('jobs/schoolSiteGenerator/trigger/' + this.schoolId)
        .then(() => {
          document.popToast('Site generation trigger successful')
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.handleUnAuthorized()
          } else {
            this.school.error = 'There was an error trying to action this request'
          }
        })
        .then(() => {
          this.school.isLoading = false
        })
    },
    handleUnAuthorized: function () {
      this.$router.push('/login')
    }
  }

})
</script>

<template>
  <div class="row">

    <div>
      <h5>School: {{ school.data.schoolName }}</h5>

      <exception-display :error="school.error"></exception-display>
      <loading-display :isLoading="school.isLoading"></loading-display>

      <div v-if="schoolId">
        <a class="btn blue" @click="renegerateSchoolWebsite()">Regenerate Website</a>
        <router-link :to="{ name: 'SchoolInvoicing', params: { schoolId: schoolId }}">
          School invoicing
        </router-link>
      </div>

      <div v-show="!school.isLoading">
        <table>
          <tr>
            <td>Business Name</td>
            <td>
              <input type="text" v-model="school.data.businessName" />
            </td>
          </tr>
          <tr>
            <td>Business Address</td>
            <td>
              <div>Street: <input type="text" v-model="school.data.street" /></div>
              <div>City: <input type="text" v-model="school.data.city" /></div>
              <div>PostCode: <input type="text" v-model="school.data.postCode" /></div>
              <div>Country: <input type="text" v-model="school.data.country" /></div>
            </td>
          </tr>
          <tr>
            <td>Is disabled</td>
            <td>
              <select v-model="school.data.isDisabled" class="browser-default">
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Is Test School</td>
            <td>
              <select v-model="school.data.isTestSchool" class="browser-default">
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Plan</td>
            <td>
              <select v-model="school.data.planId" class="browser-default">
                <option v-for="f in school.data.availablePlans" :key="f.id" :value="f.id">{{ f.name }}</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Plan Start</td>
            <td>
              <input type="text" v-model="school.data.planStart" />
            </td>
          </tr>
          <tr>
            <td>Plan Cost</td>
            <td>
              <input type="text" v-model="school.data.planCost" />
            </td>
          </tr>
          <tr>
            <td>Student cap</td>
            <td>
              <input type="number" v-model="school.data.studentCap" />
            </td>
          </tr>
          <tr>
            <td>Extra student cap</td>
            <td>
              <input type="number" v-model="school.data.extraStudentCap" />
            </td>
          </tr>
          <tr>
            <td>Site Gen: Is enabled</td>
            <td>
              <select v-model="school.data.isSiteGenEnabled" class="browser-default">
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Site Gen: Can customise theme</td>
            <td>
              <select v-model="school.data.canCustomizeSiteGenTheme" class="browser-default">
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </td>
          </tr>
        </table>

        <a @click="updateSchoolMeta()" class="btn blue">Save</a>

      </div>

    </div>

    <h5>Students <span v-show="students.list.length">({{ students.list.length }})</span></h5>

    <a v-if="!students.shouldLoad" @click="loadStudentsList()" class="btn blue">Load</a>

    <div v-if="students.shouldLoad">

      <exception-display :error="students.error"></exception-display>
      <loading-display :isLoading="students.isLoading"></loading-display>

      <div v-show="students.shouldLoad && !students.isLoading" class="col s12">
        <div class="row">
          <table v-if="!students.isLoading" class="highlight">
            <tbody>
              <tr v-for="s in students.list" v-bind:key="s.id">
                <td>
                  <router-link :to="{ name: 'Student', params: { schoolId: schoolId, studentId: s.id }}">
                    {{ s.firstName }}
                  </router-link>
                </td>
                <td>
                  <router-link :to="{ name: 'Student', params: { schoolId: schoolId, studentId: s.id }}">
                    {{ s.lastName }}
                  </router-link>
                </td>
                <td>
                  <div>{{ s.statusFormatted }}</div>
                  <div><span v-if="s.isDisabled" class="grey-text">Disabled</span></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>

  </div>
</template>
