import axios from 'axios'
import createKindeClient from '@kinde-oss/kinde-auth-pkce-js'

const apiBaseUrl = process.env.VUE_APP_APIBASEURL

const api = {
  authClient: {} as any,
  init: async function () {
    // console.debug('auth sdk init starting...')
    this.authClient = await createKindeClient({
      client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
      domain: process.env.VUE_APP_AUTH_CLIENT_DOMAIN,
      audience: process.env.VUE_APP_AUTH_CLIENT_AUDIENCE,
      is_dangerously_use_local_storage: (process.env.VUE_APP_IS_DEV === 'true'),
      redirect_uri: window.location.origin + '/login',
      logout_uri: window.location.origin + '/logout',
      on_redirect_callback: () => {
        // console.debug('redir callback', JSON.stringify({ user, appState }))
      }
    })
    // console.debug('auth sdk init completed.')
  },
  createApiClient: async function () {
    const accessToken = await this.authClient.getToken()
    if (accessToken) {
      return axios.create({
        baseURL: apiBaseUrl,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
    } else {
      return axios.create({ baseURL: apiBaseUrl })
    }
  },
  login: async function () {
    await this.authClient.login()
  },
  logout: async function () {
    localStorage.removeItem('accessToken') // remove legacy token
    localStorage.removeItem('schoolId')
    localStorage.removeItem('schoolName')
    localStorage.removeItem('schoolPhotoUrl')
    await this.authClient.logout()
  },
  getIsLoggedIn: async function () {
    const accessToken = await this.authClient.getToken()
    return !!accessToken
  },
  handleFailedRequest: function (ex: any) {
    // console.debug('e', JSON.stringify(ex))
    const status = ex.status || (ex.response ? ex.response.status : 0)
    const data = {
      code: status,
      validationErrors: [] as any[],
      error: ''
    }
    if (status === 401) {
      data.validationErrors = ['Login failed']
    } else if (status === 400) {
      const errArr = ex.response?.data?.errors || []
      if (errArr.length > 0) {
        data.validationErrors = ex.response.data?.errors || ['Invalid request']
      } else {
        data.validationErrors = [ex.message || 'Unexpected error (code 400)']
      }
    } else if (status === 404) {
      data.validationErrors = ['Item cant be found']
    } else {
      data.error = 'Oops, unexpected error! (error code ' + status + ')'
    }
    return data
  }
}

export default api
