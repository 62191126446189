<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LandingPage',
  data () {
    return {
    }
  },

  methods: {
  }

})
</script>

<template>
  <p>Welcome</p>
</template>
