<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'
import BarChart from '@/components/BarChart.vue'

export default defineComponent({
  components: { ExceptionDisplay, LoadingDisplay, BarChart },
  name: 'DbPerfAnalysisPage',
  data () {
    return {
      isLoading: false,
      error: '',
      repos: [],
      repoSelected: '',
      querySelected: '',
      queryHashSelected: '',
      repoStats: [],
      chartData: {
        labels: ['a', 'b'],
        datasets: [{
          label: 'Data',
          backgroundColor: '#0D47A1',
          data: [40, 20]
        }]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              display: false // this will remove only the label
            }
          }]
        }
      }
    }
  },

  async mounted () {
    document.runMaterializeInit()
    this.loadRepoList()
  },

  methods: {
    loadRepoList: async function () {
      this.isLoading = true
      this.error = ''
      const apiClient = await this.$api.createApiClient()
      apiClient
        .get('badmin/perf')
        .then((r) => {
          this.repos = r.data
          this.isLoaded = true
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.handleUnAuthorized()
          } else {
            this.error = 'There was an error trying to get this data'
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    loadRepoData: async function (repoName, queryHash, query) {
      this.repoSelected = repoName
      this.querySelected = query
      this.queryHashSelected = queryHash
      this.isLoading = true
      this.error = ''
      const apiClient = await this.$api.createApiClient()
      apiClient
        .get('badmin/perf/' + repoName + '/' + queryHash)
        .then((r) => {
          this.repoStats = r.data
          this.refreshChartData()
          this.isLoaded = true
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.handleUnAuthorized()
          } else {
            this.error = 'There was an error trying to get this data'
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    refreshChartData: function () {
      this.chartData.labels = this.repoStats[0].hits.map((h) => h.time)
      this.chartData.datasets[0].data = this.repoStats[0].hits.map((h) => h.charge)
    },
    handleUnAuthorized: function () {
      this.$router.push('/login')
    }
  }

})
</script>

<template>

  <div class="row">

    <h5>Perf analysis</h5>

    <exception-display :error="error"></exception-display>
    <loading-display :isLoading="isLoading"></loading-display>

    <div v-show="!isLoading" class="col s12">
      <ul class="browser-default">
        <li v-for="repo in repos" :key="repo.repo" class="browser-default">
          {{ repo.repo }}
          <ul class="browser-default">
            <li v-for="q in repo.queries" :key="q.queryHash" class="browser-default">
              <div>{{ q.query }}</div>
              <button @click="loadRepoData(repo.repo, q.queryHash, q.query)">Load</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>

  </div>
  <div v-if="!isLoading && repoStats.length > 0" class="card-panel">

    <div>
        <h6><strong>{{ repoSelected }}</strong><br/>{{ querySelected }}<br/>{{ queryHashSelected }}</h6>
    </div>

    <div class="row">

      <div v-for="stat in repoStats" :key="stat.action" class="col s12 l4">
        <!--{{ JSON.stringify(stat, null, 2) }}-->
        <table>
          <tr>
            <td>Hits</td>
            <td>{{ stat.totalHits }}</td>
          </tr>
          <tr>
            <td>Charge Avg</td>
            <td>{{ stat.chargeAvg }}</td>
          </tr>
          <tr>
            <td>Charge Min</td>
            <td>{{ stat.chargeMin }}</td>
          </tr>
          <tr>
            <td>Charge Max</td>
            <td>{{ stat.chargeMax }}</td>
          </tr>
        </table>
      </div>

      <div class="col s12 l8">
        <!--{{ JSON.stringify(stat.hits, null, 2) }}-->
        <bar-chart :chartdata="chartData" :options="chartOptions" />
      </div>

    </div>
  </div>

</template>
