<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

export default defineComponent({
  components: { ExceptionDisplay, LoadingDisplay },
  name: 'SignInFeedPage',
  data () {
    return {
      isLoading: false,
      error: '',
      hoursAgo: 24,
      showStudentColumn: false,
      signIns: []
    }
  },

  methods: {
    loadFeed: async function () {
      this.isLoading = true
      this.error = ''
      const apiClient = await this.$api.createApiClient()
      apiClient
        .get('badmin/signIns/feed?hoursAgo=' + this.hoursAgo)
        .then((r) => {
          this.signIns = r.data
          this.isLoaded = true
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.handleUnAuthorized()
          } else {
            this.error = 'There was an error trying to get this data'
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    handleUnAuthorized: function () {
      this.$router.push('/login')
    }
  }

})
</script>

<template>

  <div class="row">

    <h5>Sign Ins</h5>

    <exception-display :error="error"></exception-display>
    <loading-display :isLoading="isLoading"></loading-display>

    <div v-show="!isLoading" class="col s12">

      <div class="section">
        <div>
          <input v-model="hoursAgo" type="number" /> hours ago
        </div>
        <div class="section">
          Show Student Column
          <div class="switch">
              <label>
                No
                <input v-model="showStudentColumn" type="checkbox">
                <span class="lever"></span>
                Yes
              </label>
            </div>
        </div>
        <div>
          <a @click="loadFeed()" class="btn blue">Search</a>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>School</th>
            <th>Time</th>
            <th v-show="showStudentColumn">Student</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="s in signIns" :key="s.id">
            <td>{{ s.schoolName }}</td>
            <td>{{ s.timestampFormatted }}</td>
            <td v-show="showStudentColumn">{{ s.studentId }}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

</template>
