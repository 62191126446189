<script lang="ts">
export default {
  props: {
    isLoading: Boolean
  }
}
</script>

<template>
  <div v-if="isLoading" class="row">
    <div class="center">
      <div class="lds-dual-ring"></div>
    </div>
  </div>
</template>
