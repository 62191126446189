import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from '@/data/ApiLayer'

api.init().then(function () {
  const app = createApp(App)
  app.use(store)
  app.use(router)

  app.config.globalProperties.$api = api
  app.mount('#app')
})
