<script lang="ts">
export default {
  props: {
    error: String
  }
}
</script>

<template>
  <div v-if="error" class="row">
    <div class="error-message">
      <p class="valign-wrapper"><i class="material-icons">error</i> {{ error }}</p>
    </div>
  </div>
</template>
