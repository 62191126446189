<script>
import { defineComponent } from 'vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

export default defineComponent({
  components: { ExceptionDisplay, LoadingDisplay },
  name: 'EziCustomerPage',
  data () {
    return {
      isLoading: false,
      error: '',
      schoolId: '',
      studentId: '',
      customer: {
        isEmpty: true
      }
    }
  },

  async mounted () {
    this.schoolId = this.$route.params.schoolId
    this.studentId = this.$route.params.studentId
    this.loadCustomer()
  },

  methods: {
    loadCustomer: async function () {
      this.isLoading = true
      this.error = ''
      const apiClient = await this.$api.createApiClient()
      apiClient
        .get('badmin/ezidebit/schools/' + this.schoolId + '/students/' + this.studentId)
        .then((r) => {
          console.log('r', r)
          this.customer = r.data
        })
        .catch((e) => {
          console.log('e', e)
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.handleUnAuthorized()
          } else {
            this.error = 'There was an error trying to get this data'
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    handleUnAuthorized: function () {
      this.$router.push('/login')
    }
  }

})
</script>

<template>
  <div class="row">

    <h5>Ezibit Customer</h5>

    <exception-display :error="error"></exception-display>
    <loading-display :isLoading="isLoading"></loading-display>

    <div v-if="!isLoading" class="col s12">
      <div class="row">
        <pre>
          {{ JSON.stringify(customer, null, 2) }}
        </pre>
      </div>
    </div>

  </div>
</template>
