<script>
import { defineComponent } from 'vue'

import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'

export default defineComponent({
  components: { ExceptionDisplay, LoadingDisplay },
  name: 'SchoolsPage',
  data () {
    return {
      isLoading: false,
      error: '',
      schools: []
    }
  },

  async mounted () {
    document.runMaterializeInit()
    this.loadSchoolsList()
  },

  methods: {
    loadSchoolsList: async function () {
      this.isLoading = true
      this.error = ''
      const apiClient = await this.$api.createApiClient()
      apiClient
        .get('badmin/schools')
        .then((r) => {
          this.schools = r.data
          this.isLoaded = true
        })
        .catch((e) => {
          const status = e.status || (e.response ? e.response.status : 0)
          console.debug('status', status)
          if (status === 401) {
            this.handleUnAuthorized()
          } else {
            this.error = 'There was an error trying to get this data'
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },
    handleUnAuthorized: function () {
      this.$router.push('/login')
    }
  }

})
</script>

<template>

  <div class="row">

    <h5>Schools</h5>

    <exception-display :error="error"></exception-display>
    <loading-display :isLoading="isLoading"></loading-display>

      <router-link :to="{ name: 'Register School' }" class="btn blue">
        Register
      </router-link>

    <div v-show="!isLoading" class="col s12">
      <ul class="collapsible">
        <li v-for="school in schools" :key="school.id">
          <div class="collapsible-header">
              {{ school.name }} ({{ school.email }})
              <span v-if="!school.isDisabled" class="green-text">&nbsp;[Enabled]</span>
              <span v-if="school.isDisabled" class="red-text">&nbsp;[Disabled]</span>
              <span v-if="school.isTestSchool" class="purple-text">&nbsp;[Test]</span>
          </div>
          <div class="collapsible-body">
            <div>
                <router-link :to="{ name: 'School', params: { schoolId: school.id }}">
                  {{ school.name }}
                </router-link>
            </div>
            <div>
              Plan: {{ school.plan.planName }}
            </div>
            <!--
            <div>
              <form asp-action="LogInAsSchool" method="post" target="_blank" style="display:inline-block;">
                <input id="schoolId" name="schoolId" type="hidden" class="browser-default" value="@school.Id" />
                <button type="submit">Log In As School</button>
              </form>
              <form asp-action="GetWeeklyEmailContents" method="get" target="_blank" style="display:inline-block;">
                <input id="schoolId" name="schoolId" type="hidden" class="browser-default" value="@school.Id" />
                <button type="submit">View Weekly Email contents</button>
              </form>
              @if (!school.IsDisabled)
              {
                <form asp-action="DisableSchool" method="post" style="display:inline-block;">
                    <input id="schoolId" name="schoolId" type="hidden" class="browser-default" value="@school.Id" />
                    <button type="submit">Disable School</button>
                </form>
              }
              else
              {
                <form asp-action="EnableSchool" method="post" style="display:inline-block;">
                    <input id="schoolId" name="schoolId" type="hidden" class="browser-default" value="@school.Id" />
                    <button type="submit">Enable School</button>
                </form>
              }
              <form asp-action="SetAllStudentsToGetEmail" method="post" style="display:inline-block;">
                  <input id="schoolId" name="schoolId" type="hidden" class="browser-default" value="@school.Id" />
                  <button type="submit">Set all students to recieve emails</button>
              </form>

              <form asp-action="SetPlan" method="post" style="display:inline-block;">
                  <input id="schoolId" name="schoolId" type="hidden" class="browser-default" value="@school.Id" />
                  <div>
                      <label for="planId" class="control-label">Plan:</label>
                      <select id="planId" name="planId">
                          @foreach (var plan in Model.AllPlans)
                          {
                              var isSelected = ((int)school.PlanId == int.Parse(plan.Value));
                              if (isSelected)
                              {
                                  <option value="@plan.Value" selected="selected">@plan.Text</option>
                              }
                              else
                              {
                                  <option value="@plan.Value">@plan.Text</option>
                              }
                          }
                      </select>
                  </div>
                  <button type="submit">Update plan</button>
              </form>
            </div>
            -->
            <span>
                <pre>{{ JSON.stringify(school, null, 2) }}</pre>
            </span>
            <!--
            <div class="section">
                <form asp-action="SendSchoolWelcomeEmail" method="post">
                    <label for="email">Send Welcome Email:</label>
                    <input id="email" name="email" type="hidden" value="@school.Email" />
                    <button type="submit">Send</button>
                </form>
            </div>
            -->
          </div>
        </li>
      </ul>
    </div>

  </div>

</template>
